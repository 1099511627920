<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";
import { isObject } from 'lodash';

export default {
  components: {
    MasterDetail
  },
  // mixins: [],
  // directives: {},
  data() {
    return {
      opts: {}
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "id",
          name: "Código",
          hideInform: true,
          hideInTable: true,
          width: "50px"
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }]
        },
        {
          key: "permissoes",
          name: "Permissões",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }]
        }
      ];
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/nivel-hierarquicos`;
    },
    customResource() {
      const notify = msg =>
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "alert",
          title: "Mockado",
          text: msg
        });
      return {
        async get(...args) {
          console.log(
            "Timesheet customResource GET",
            isObject(args) ? { ...args } : args
          );
          notify("GET não implementado");
          return [];
        },
        async save(...args) {
          console.log(
            "Timesheet customResource SAVE",
            isObject(args) ? { ...args } : args
          );
          notify("SAVE não implementado");
          return {};
        },
        async patch(...args) {
          console.log(
            "Timesheet customResource PATCH",
            isObject(args) ? { ...args } : args
          );
          notify("PATCH não implementado");
          return {};
        },
        async delete(...args) {
          console.log(
            "Timesheet customResource DELETE",
            isObject(args) ? { ...args } : args
          );
          notify("DELETE não implementado");
          return {};
        }
      };
    }
  },
  // filters: {},
  // created() {},
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {}
  // watch: {},
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :cols="cols"
    :customResource="customResource"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
  />
</template>
